<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ $t("app.status") }}
      </h4>
      <div
        class="font-size-14 col-12"
        :dir="$i18n.locale == 'ar' ? 'rtl' : 'rtl'"
        :style="$i18n.locale == 'ar' ? '' : 'text-align: left'"
      >
        <div class="col-12 form-check mb-3">
          <input
            class="form-check-input"
            type="radio"
            name="formRadios"
            id="formRadios1"
            value="1"
            :checked="row.status == 1 ? true : false"
            v-on:input="onRadioChange"
          />
          <label
            class="form-check-label"
            :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            for="formRadios1"
          >
            {{ $t("app.active") }}
          </label>

          <input
            class="form-check-input"
            type="radio"
            name="formRadios"
            id="formRadios2"
            style="margin-right: 20px"
            value="0"
            :checked="row.status == 0 ? true : false"
            v-on:input="onRadioChange"
          />
          <label
            class="form-check-label"
            :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            for="formRadios2"
            style="margin-right: 40px"
          >
            {{ $t("app.inactive") }}
          </label>
        </div>

        <div v-if="hasShowInHeader">
          <input
            type="checkbox"
            value="0"
            :checked="row.show_in_header == 1 ? true : false"
            v-on:input="onHeaderChange"
          />&nbsp;&nbsp;
          <label
            :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          >
            {{ $t("app.showInHeader") }}
          </label>
        </div>

        <div v-if="hasShowInFooter">
          <input
            type="checkbox"
            value="0"
            :checked="row.show_in_footer == 1 ? true : false"
            v-on:input="onFooterChange"
          />&nbsp;&nbsp;
          <label
            :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          >
            {{ $t("app.showInFooter") }}
          </label>
        </div>

        <div v-if="hasShowInSupport">
          <input
            type="checkbox"
            value="0"
            :checked="row.show_in_support == 1 ? true : false"
            v-on:input="onSupportChange"
          />&nbsp;&nbsp;
          <label
            :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          >
            {{ $t("app.showInSupport") }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardStatus",
  props: [
    "status",
    "hasShowInHeader",
    "hasShowInFooter",
    "hasShowInSupport",

    "show_in_header",
    "show_in_footer",
    "show_in_support",
  ],
  data() {
    return {
      row: {
        status: this.$props.status ?? false,
        show_in_header: this.$props.show_in_header ?? false,
        show_in_footer: this.$props.show_in_footer ?? false,
        show_in_support: this.$props.show_in_support ?? false,
      },
    };
  },
  mounted() {},
  methods: {
    onStatusChange() {
      const data = {
        status: this.row.status,
        show_in_header: this.row.show_in_header,
        show_in_footer: this.row.show_in_footer,
        show_in_support: this.row.show_in_support,
      };
      this.$emit("statusChange", data);
    },

    onRadioChange() {
      this.row.status = this.row.status ? 0 : 1;
      this.onStatusChange();
    },

    onHeaderChange() {
      this.row.show_in_header = this.row.show_in_header ? 0 : 1;
      this.onStatusChange();
    },

    onFooterChange() {
      this.row.show_in_footer = this.row.show_in_footer ? 0 : 1;
      this.onStatusChange();
    },

    onSupportChange() {
      this.row.show_in_support = this.row.show_in_support ? 0 : 1;
      this.onStatusChange();
    },
  },
};
</script>
